import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as crypto from "crypto-js";
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { TokenStorageService } from './token-storage.service';
const USER_KEY = environment.USER_KEY;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    public jwtHelper: JwtHelperService,
    public router: Router,
    private tkss: TokenStorageService) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': 'POST,GET',
      'Access-Control-Allow-Methods': 'POST',
    })
  }

  login(req: any): Observable<any> {
    return this.http.post<any>(environment.BASE_URL2 + `/access/login`, req, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.errorHandl)
      )
  }

  // ...
  public isAuthenticated(): boolean {
    const token = this.tokenStorageService.getToken();
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public redirectOnSesion() {
    const userenc = localStorage.getItem(USER_KEY) ? localStorage.getItem(USER_KEY) : null;
    if (userenc) {
      const user = JSON.parse(crypto.AES.decrypt(userenc, environment.SITE_KEY).toString(crypto.enc.Utf8)).user;
      this.tkss.saveUser(user);
      this.tkss.saveToken(user.token);
      this.tkss.savePermissionsStorage(user.menuOptions);
      this.router.navigate(['/planeador/indicators/campaign']);
    }
  }

  // Error handling
  errorHandl(error) {
    return throwError(error.error.error);
  }
}
